@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@font-face {
   font-family: 'OpenSans-SemiBold';
   src: url(../fonts/OpenSans/OpenSans-SemiBold.ttf);
}

html,
body {
   height: 100%;
}

#root {
   height: 100%;
   width: 100%;
}

@font-face {
   font-family: 'OpenSans';
   src: url(../fonts/OpenSans/OpenSans-Regular.ttf);
}

* {
   font-family: 'OpenSans';
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   transition: background-color 5000s ease-in-out 0s;
}
